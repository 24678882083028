<template>
    <b-form-group>
        <label>Color</label>
        <b-row>
            <b-col>
                <b-form-group>
                    <b-form-input type="color" :value="value" v-on:input="val => $emit('input', val)"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group>
                    <b-form-input :value="value" v-on:input="val => $emit('input', val)"/>
                </b-form-group>
            </b-col>
        </b-row>
    </b-form-group>
</template>
<script>
    import {BCol, BFormGroup, BFormInput, BRow} from 'bootstrap-vue'

    export default {
        props: {
            value: {
                type: String,
                required: true
            }
        },
        components: {
            BFormGroup,
            BFormInput,
            BRow,
            BCol
        }
    }
</script>